<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card outlined class="primary--border">
          <v-card-title class="title">
            Customizer ({{ $route.query.title }})
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card outlined>
            <v-card-title class="pb-0 mb-0">
              <v-flex xs3>
                <v-fee-head-field
                  v-model="fee_head_id"
                  :disabled="
                    $route.query.title && $route.query.title.length > 0
                  "
                  :onlyCustomizable="true"
                ></v-fee-head-field>
              </v-flex>
              <v-flex xs3>
                <v-grade-field :inside="true" v-model="gradeId"></v-grade-field>
              </v-flex>
              <v-flex xs3>
                <v-section-field
                  v-model="section"
                  :gradeId="gradeId"
                ></v-section-field>
              </v-flex>
              <v-flex xs2>
                <search-button
                  @action="get"
                  :disabled="!gradeId || !fee_head_id || !section"
                  :permission="true"
                ></search-button>
              </v-flex>
            </v-card-title>
          </v-card>

          <v-container
            v-if="
              doesHaveOldbatch === true &&
                studentWithCustomizedHeads.length < 1 &&
                grade.section.selected &&
                batch.is_current_batch &&
                form.items.data.length > 0 &&
                !form.loading &&
                feeCharge.amount
            "
          >
            <alert-message type="error" title="No Customized Head has been set">
              <template v-slot:message>
                No Customized fee head record defined for the selected grade &
                section. Would you like to migrate the customized head record
                from old batch?
                <br />
                <a href="" @click.prevent="fetchCustomizedHeadFromOldBatch"
                  >Click Here</a
                >
              </template>
            </alert-message>
            <br />
          </v-container>

          <v-container>
            <alert-message
              v-if="(feeCharge && feeCharge.fee_head) || feeCharge === null"
              :type="feeCharge === null ? 'warning' : 'primary'"
              :title="
                feeCharge === null
                  ? `Fee not set (${$route.query.title})`
                  : `Fee Amount (${feeCharge.amount.currency()})`
              "
              :message="
                feeCharge === null
                  ? 'Fee Amount has not been set for the selected fee head. However you can still proceed with the customization. If you wish to set fee amount go to Billing > Setting > Fee Charge'
                  : `This is set fee amount on fee head (${feeCharge.fee_head.title}) for selected batch and grade.`
              "
            ></alert-message>
          </v-container>

          <v-data-table
            :headers="headers"
            :items="form.items.data"
            :search="search"
            sort-by="title"
            :options.sync="pagination"
            :footer-props="footerProps"
            :server-items-length="form.items.meta.total"
            :loading="form.loading"
            hide-default-footer
          >
            <template v-slot:header.select>
              <v-checkbox
                :input-value="
                  selectedStudents.length === form.items.data.length &&
                    selectedStudents.length !== 0
                "
                :indeterminate="
                  selectedStudents.length !== 0 &&
                    selectedStudents.length < form.items.data.length
                "
                style="margin-top: 0; padding-top: 0"
                :disabled="form.items.data.length < 1"
                @click.stop="toggleSelectStudent"
                class="ml-0"
                primary
                hide-details
              ></v-checkbox>
            </template>
            <template v-slot:item="{ index, item }">
              <tr>
                <td>
                  <v-checkbox
                    style="margin-top: 0; padding-top: 0"
                    :input-value="selectedStudents.includes(item)"
                    @click.stop="selectStudent(item)"
                    primary
                    hide-details
                  >
                  </v-checkbox>
                </td>
                <td>{{ index + form.items.meta.from }}</td>
                <td class="text-xs-left">
                  {{ item.student ? item.student.name : "-" }} <br />
                  <small style="color: #666">{{
                    item.student ? item.student.enroll_code : ""
                  }}</small>
                </td>
                <td class="text-xs-right">
                  {{
                    item.student && item.student.roll_no
                      ? item.student.roll_no
                      : "-"
                  }}
                </td>
                <td class="text-xs-left">
                  <v-text-field
                    :tabindex="index + 1"
                    @focus="$event.target.select()"
                    v-model="item.amount"
                    @keyup="enableSave = true"
                    placeholder="##,##,###"
                    @keyup.enter="save(item)"
                    type="number"
                    outlined
                    dense
                    class="mt-2 mb-2"
                  ></v-text-field>
                </td>

                <td class="text-right">
                  <delete-button
                    :disabled="!item.id"
                    permission="fee-heads-delete"
                    @agree="deleteRecord(item.id)"
                  />
                </td>
              </tr>
            </template>
            <v-alert
              slot="no-results"
              :value="true"
              color="error"
              icon="warning"
            >
              Your search for "{{ search }}" found no results.
            </v-alert>
          </v-data-table>

          <br />

          <v-btn
            :disabled="
              !(enableSave || (selectedStudents.length > 0 && isValid))
            "
            v-if="form.items.data.length > 0"
            @click="save()"
            large
            block
            color="primary"
            >Save Customized Records</v-btn
          >
        </v-card>
      </v-flex>
    </v-layout>

    <v-dialog v-model="migrateDialog" persistent max-width="800px">
      <v-card>
        <v-card-title class="primary white--text">
          <span class="title">Customized Record From {{ oldBatch.name }}</span>
          <v-spacer></v-spacer>
          <a href="" @click.prevent="migrateDialog = false"
            ><v-icon color="white">close</v-icon></a
          >
        </v-card-title>

        <v-card-text class="pb-1">
          <v-flex>
            <br />
            <v-text-field
              disabled
              outlined
              dense
              autocomplete="off"
              label="Migrate From Batch"
              required
              :value="oldBatch.name"
              name="fare_amount"
            />
            <v-grade-field
              @selectedGrade="selectedFromGrade"
              v-model="oldGradeId"
              :inside="true"
              label="Migrate From Grade"
              :batch-id="oldBatch.id"
            />
          </v-flex>
          <br />
          <div style="display: flex;">
            <div class="data-represent" style="margin-top: 8px">
              <div>
                <span class="d-success"></span> &nbsp;
                <small> {{ oldCustomizedList.length }} Total Students</small>
              </div>
              <div>
                <span class="d-primary"></span> &nbsp;
                <small
                  ><strong>{{
                    oldCustomizedList
                      .map((item) => parseFloat(item.amount * 1))
                      .reduce((pre, cur) => pre + cur, 0)
                      .currency()
                  }}</strong>
                  Total Amout</small
                >
              </div>
            </div>
          </div>

          <v-data-table
            :headers="oldRecordHeader"
            :hide-default-footer="true"
            :items="oldCustomizedList"
            :footer-props="footerProps"
            sort-by="roll"
            :options.sync="pagination"
            :server-items-length="oldCustomizedList.length"
          >
            <template v-slot:item="{ index, item }">
              <tr :key="index">
                <td>
                  {{ item.student.name }} <br />
                  <small class="text-light">{{
                    item.student.enroll_code
                  }}</small>
                </td>
                <td class="text-right">
                  <strong>{{ item.amount.currency() || "-" }}</strong>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
        <br />
        <v-container>
          <alert-message
            v-if="feeCharge.amount"
            :type="'warning'"
            extraSmall
            :title="'Amount will be replaced by new settings'"
            :message="
              `Amount from previous batch record will be overridden by the new fee setup. i.e ${this.feeCharge.amount.currency()}`
            "
          ></alert-message>
        </v-container>
        <br />

        <v-card-actions>
          <v-btn
            :disabled="oldCustomizedList.length < 1"
            color="success"
            block
            large
            outlined
            @click="migrateCustomizedHead"
            >Migrate to {{ batch.name }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import Form from "@/library/Form";
import Mixins from "@/library/Mixins";
import { hasOldBatch } from "@/library";

export default {
  mixins: [Mixins],
  data: () => ({
    oldGradeId:'',
    valid: true,
    lazy: false,
    gradeId: "",
    section: "",
    fee_head_id: "",
    enableSave: false,
    feeCharge: {},
    form: new Form({}, "/api/customized-fee"),
    search: null,
    selectedStudents: [],
    studentWithCustomizedHeads: [],
    oldCustomizedList: [],
    headers: [
      { text: "", align: "left", value: "select", width: 3, sortable: false },
      { text: "#", align: "center", value: "id", width: 50, sortable: false },
      { text: "Student", align: "left", value: "title", sortable: false },
      {
        text: "Roll No",
        align: "left",
        value: "title",
        sortable: false,
        width: 90,
      },
      {
        text: "Amount",
        align: "left",
        value: "amount",
        sortable: false,
        width: 200,
      },
      { text: "Action", align: "right", sortable: false, width: 350 },
    ],
    oldRecordHeader: [
      {
        text: "Name",
        align: "left",
        value: "name",
        sortable: false,
      },
      {
        text: "Amount",
        align: "right",
        value: "type",
        sortable: false,
        width: 180,
      },
    ],
    oldBatch: {},
    migrateDialog: false,
  }),

  computed: {
    ...mapState(["batch", "batches", "grade"]),
    isValid() {
      let isValid = false;
      this.form.items.data.forEach((item) => {
        if (item.amount > 0) {
          isValid = true;
        }
      });
      return isValid;
    },
    doesHaveOldbatch() {
      return hasOldBatch(this.batches);
    },
  },
  mounted() {
    this.fee_head_id = this.$route.query.fee_head_id
      ? parseInt(this.$route.query.fee_head_id)
      : null;
  },

  watch: {
    gradeId(value) {
      this.form.items.data = [];
      this.fetchFee(value);
    },
    section() {
      this.form.items.data = [];
    },
  },

  methods: {
    selectedFromGrade(grade) {
      let query = this.queryString(
        `grade=${grade.id}&fee_head_id=${this.fee_head_id}&rowsPerPage=1000&batchId=${this.oldBatch.id}&forMigration=true`
      );
      this.$rest.get(`/api/customized-fee${query}`).then((response) => {
        this.oldCustomizedList = response.data.data;
      });
    },
    get(params) {
      if (!this.fee_head_id) return;
      let query = [null, undefined].includes(params)
        ? this.queryString(
            `section=${this.section}&grade=${this.gradeId}&fee_head_id=${this.fee_head_id}`
          )
        : params;
      this.selectedStudents = [];
      this.form.get(null, query).then(({ data }) => {
        this.studentWithCustomizedHeads = [];
        data.data.map((item) => {
          if (item.id) {
            this.selectedStudents.push(item);
            this.studentWithCustomizedHeads.push(item);
          }
        });
      });
    },

    deleteRecord(id) {
      this.form.fireFetch = false;
      this.form.delete(id).then(() => {
        this.get();
      });
    },

    selectStudent(item) {
      if (this.feeCharge && Object.keys(this.feeCharge).length > 0) {
        item.amount = this.feeCharge.amount;
      }
      this.enableSave = true;
      if (this.selectedStudents.indexOf(item) === -1) {
        this.selectedStudents.push(item);
      } else {
        item.amount = null;
        this.selectedStudents.splice(this.selectedStudents.indexOf(item), 1);
      }
    },

    toggleSelectStudent() {
      if (
        this.selectedStudents.length === 0 ||
        this.selectedStudents.length < this.form.items.data.length
      ) {
        this.enableSave = true;
        this.form.items.data = this.form.items.data.map((item) => {
          return {
            ...item,
            amount: this.feeCharge ? this.feeCharge.amount : null,
          };
        });
        this.selectedStudents = [...this.form.items.data];
      } else {
        this.form.items.data = this.form.items.data.map((item) => {
          return {
            ...item,
            amount: null,
          };
        });
        this.selectedStudents = [];
      }
    },

    fetchFee(grade) {
      this.$rest
        .get(`/api/fee/${this.fee_head_id}?grade=${grade}`)
        .then((res) => {
          this.feeCharge = res.data.data;
        })
        .catch((err) => {
          this.feeCharge = null;
        });
    },

    fetchCustomizedHeadFromOldBatch() {
      let finalBatches = this.batches.filter((item) => {
        return item.is_hs === this.batch.is_hs && item.id !== this.batch.id;
      });

      let oldBatch = finalBatches[finalBatches.length - 1];

      if (oldBatch && !oldBatch.is_current_batch) {
        this.oldCustomizedList = [];
        this.oldBatch = oldBatch;
        this.migrateDialog = true;
        this.oldGradeId = "";
      }
    },

    migrateCustomizedHead() {
      this.$rest
        .post("/api/customized-fee/migrate", {
          grade_id: this.gradeId,
          batch_id: this.batch.id,
          records: this.oldCustomizedList,
          fee_head_id: this.fee_head_id,
          fee_charge: this.feeCharge,
        })
        .then((res) => {
          this.migrateDialog = false;
          this.get();
          this.$events.fire("notification", {
            message: res.data.message,
            status: "success",
          });
        })
        .catch((err) => {
          this.$events.fire("notification", {
            message: err.response.data.message,
            status: "error",
          });
        });
    },

    save(item) {
      this.$rest
        .post("/api/customized-fee", {
          fee_head_id: this.fee_head_id,
          records: item
            ? [item]
            : this.form.items.data.filter((item) => item.amount > 0),
        })
        .then((res) => {
          this.$events.fire("notification", {
            message: res.data.message,
            status: "success",
          });
          this.enableSave = false;
          this.get();
        })
        .catch((err) => {
          this.$events.fire("notification", {
            message: err.response.data.message,
            status: "error",
          });
        })
        .finally(() => {});
    },
  },
};
</script>
