import storage from './Storage';

const hasOldBatch = (batches) => {
    let output = false;
    if (batches && batches.length > 1) {
        let currentSelectedBatch = JSON.parse(storage.get('batch'));
        batches.map((item) => {
            if ((item.is_hs === currentSelectedBatch.is_hs) && item.id < currentSelectedBatch.id) {
                output = true;
            }
        })
    }
    return output;
}

export { hasOldBatch };